import React, { useState } from 'react';
import { PROD_or_DEV } from '../config';
import LocationsMap from "../components/LocationsMap";
import IslandMap from '../components/IslandMap';
import './maingameapp.css'; // Import the specific CSS file for this page




export function MainGameApp() {
    return (
        <div className="App">
          <h1>Dinosaur Discovery Game</h1>
          <IslandMap />
          {/* You can add more components that make up your game's UI here */}
        </div>
      );
    }
