// ApiDataLoader.tsx
import React from 'react';
import axios from 'axios';

const ApiDataLoader = () => {
  const loadData = (apiPath: string) => {
    axios.post(`/api/load/${apiPath}`).then(() => {
      alert('Data loaded successfully');
    }).catch((error) => {
      alert('Error loading data');
      console.error(error);
    });
  };

  const handleLoadCoins = () => {
    axios.get('/load-coins', {
      headers: {
        'X-API-KEY': '2348230e2e2hru2hr832hdu2eh92efh' // Replace 'your_secret_api_key' with the actual API key
      }
    })
    .then(() => alert('Coin data loaded successfully'))
    .catch(error => console.error('Error loading coin data:', error));
  };

  const handleLoadNFTs = () => {
    axios.get('/load-nfts', {
      headers: {
        'X-API-KEY': '2348230e2e2hru2hr832hdu2eh92efh' // Replace 'your_secret_api_key' with the actual API key
      }
    })
    .then(() => alert('NFT data loaded successfully'))
    .catch(error => console.error('Error loading NFT data:', error));
  };


  const handleSendOneHTMLmail = () => {
    axios.get('/send-reg-email', {
      headers: {
        'X-API-KEY': '2348230e2e2hru2hr832hdu2eh92efh' // Replace 'your_secret_api_key' with the actual API key
      }
    })
    .then(() => alert('NFT data loaded successfully'))
    .catch(error => console.error('Error loading NFT data:', error));
  };
  
  
  // In your return statement within ApiDataLoader component:
  <button onClick={handleLoadCoins}>Load Coins</button>
  

  return (
    <div>
      <h2>API Data Loader</h2>
      {/* Define your API endpoints as needed */}
      <button onClick={handleLoadCoins}>Load Coins Data</button>
      <button onClick={handleLoadNFTs}>Load NFTs Data</button>
      <button onClick={handleSendOneHTMLmail}>SendOneHTMLmail</button>
    </div>
  );
};

export default ApiDataLoader;
