// src/GuessTheNumber.tsx
import React, { useState } from 'react';

const GuessTheNumber: React.FC = () => {
  const [number, setNumber] = useState(Math.floor(Math.random() * 100) + 1);
  const [guess, setGuess] = useState('');
  const [message, setMessage] = useState('Guess a number between 1 and 100');
  const [attempts, setAttempts] = useState(0);

  const handleGuess = () => {
    const numGuess = parseInt(guess, 10);
    setAttempts(attempts + 1);
    if (numGuess === number) {
      setMessage(`Correct! The number was ${number}. It took you ${attempts + 1} attempts.`);
      setNumber(Math.floor(Math.random() * 100) + 1); // Reset the number
      setAttempts(0); // Reset attempts
    } else if (numGuess < number) {
      setMessage('Too low! Try again.');
    } else {
      setMessage('Too high! Try again.');
    }
    setGuess(''); // Reset input field
  };

  return (
    <div>
      <p>{message}</p>
      <input
        type="number"
        value={guess}
        onChange={(e) => setGuess(e.target.value)}
        onKeyDown={(e) => e.key === 'Enter' && handleGuess()}
      />
      <button onClick={handleGuess}>Guess</button>
    </div>
  );
};

export default GuessTheNumber;
