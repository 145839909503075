import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const UpdateRow = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { record, selectedTable } = location.state as { record: any; selectedTable: string };
  const [formData, setFormData] = useState(record);

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await axios.post(`/api/tables/${selectedTable}/update`, formData);
      alert('Record updated successfully');
      navigate('/coinz'); // Navigate back to the main page or wherever is appropriate
    } catch (error) {
      console.error('Error updating record:', error);
      alert('Failed to update record');
    }
  };

  const handleDelete = async () => {
    if(window.confirm("Are you sure you want to delete this record?")) {
      try {
        // Assuming you have an 'id' field in your record to identify it
        await axios.delete(`/api/tables/${selectedTable}/delete/${record.id}`);
        alert('Record deleted successfully');
        navigate('/coinz'); // Navigate back after deletion
      } catch (error) {
        console.error('Error deleting record:', error);
        alert('Failed to delete record');
      }
    }
  };

  return (
    <div>
      <h2>Update Record</h2>
      <form onSubmit={handleSubmit}>
        {Object.keys(formData).map((key) => (
          <div key={key}>
            <label>{key}:</label>
            <input
              type="text"
              name={key}
              value={formData[key] || ''}
              onChange={handleFormChange}
            />
          </div>
        ))}
        <button type="submit">Submit</button>
      </form>
      <button onClick={handleDelete} style={{marginTop: "20px", color: "red"}}>Delete Record</button>
    </div>
  );
};

export default UpdateRow;
