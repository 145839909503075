import React, { useState } from 'react';
import axios from 'axios';
import { AxiosError } from 'axios'; // Import AxiosError for type assertion
import { PROD_or_DEV } from '../config';

console.log(PROD_or_DEV); // Use it as needed
let MASTERURLPREFIX : string = "UNKNOWN/";
const prodString: string = "PROD"
const devString: string = "DEV"

if (PROD_or_DEV === prodString) {
  MASTERURLPREFIX = "https://boocrewnft.co.uk/";
  console.log("We are in the production environment.");
} else if (PROD_or_DEV === devString) {
  MASTERURLPREFIX = "http://localhost:5000/";
  console.log("We are in the development environment.");
} else {
  console.log("The environment is not specified as 'DEV' or 'PROD'.");
}




function SignupForm() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => { // Explicitly type 'e'
    e.preventDefault();
    try {
      const endpoint = "register";
      const fullURL = `${MASTERURLPREFIX}${endpoint}`;
      const response = await axios.post(fullURL, { username, password });
      alert(response.data.message);
    } catch (error) {
      // Type assertion to handle the Axios error
      if (axios.isAxiosError(error)) {
        const serverError = error as AxiosError<{ message: string }>; // Asserting the error type
        if (serverError && serverError.response) {
          alert('Signup failed: ' + serverError.response.data.message);
          console.error('Signup failed:', serverError.response.data.message);
        }
      } else {
        // Handle non-Axios errors
        console.error('An unexpected error occurred:', error);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Sign Up</h2>
      <div>
        <label>
          Username:
          <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} required />
        </label>
      </div>
      <div>
        <label>
          Password:
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
        </label>
      </div>
      <button type="submit">Sign Up</button>
    </form>
  );
}

export default SignupForm;
