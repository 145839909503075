// src/pages/Methods.tsx
import React, { useState } from 'react';
import { PROD_or_DEV } from '../config';

console.log(PROD_or_DEV); // Use it as needed
let MASTERURLPREFIX : string = "UNKNOWN/";
const prodString: string = "PROD"
const devString: string = "DEV"

if (PROD_or_DEV === prodString) {
  MASTERURLPREFIX = "https://boocrewnft.co.uk/";
  console.log("We are in the production environment.");
} else if (PROD_or_DEV === devString) {
  MASTERURLPREFIX = "http://localhost:5000/";
  console.log("We are in the development environment.");
} else {
  console.log("The environment is not specified as 'DEV' or 'PROD'.");
}

const Tweety: React.FC = () => {
  const [readMessage, setMessage] = useState(''); // State to hold the message for the text box
  const [editableMessage, setEditableMessage] = useState(''); // State for the editable text box
  const [prepMessage, setPrepMessage] = useState(''); // State for the editable text box
  

  const handleButtonClick = async (methodNumber: number) => {
    let url = '';
    let bodydata = '';
    let endpoint = "/UNKNOWN";
  
    // Determine the URL based on the methodNumber
    if (methodNumber === 5) {
      //url = 'http://localhost:5000/get-tweet-v1';
      endpoint = "get-tweet-v1"
      bodydata = JSON.stringify({ message: editableMessage });
    } else if (methodNumber === 6) {
      //url = 'http://localhost:5000/run-oai-v1';
      endpoint = "run-oai-v1"
      bodydata = JSON.stringify({ message: editableMessage });
    } else if (methodNumber === 7) {
      //url = 'http://localhost:5000/post-tweet-raw';
      endpoint = "post-tweet-raw"
      bodydata = JSON.stringify({ message: editableMessage });
    } else if (methodNumber === 8) {
      //url = 'http://localhost:5000/post-tweet-oai';
      endpoint = "post-tweet-oai"
      bodydata = JSON.stringify({ message: prepMessage });
    } else {
      console.error('Invalid method number');
      return;
    }
  
    // Execute the fetch request using the determined URL
    try {
      url = `${MASTERURLPREFIX}${endpoint}`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: bodydata,
      });
      const data = await response.json();
      setMessage(data.message); // Update the non-editable text box with the response
      setPrepMessage(data.message)
    } catch (error) {
      console.error('Error fetching data:', error);
      setMessage('Failed to fetch data');
    }
  };
  


  const handleEditableChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditableMessage(event.target.value);
  };

  const handlePrepChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPrepMessage(event.target.value);
  };

  return (
<div style={{ textAlign: 'center' }}>
<h1>Tweets Page</h1>
      <p>This is the Tweets page.</p>
      <div>
        <button onClick={() => handleButtonClick(5)}>Get Tweets</button>
        <button onClick={() => handleButtonClick(6)}>Run OAI</button>
        <button onClick={() => handleButtonClick(7)}>Post Tweet RAW</button>
        <button onClick={() => handleButtonClick(8)}>Post Tweet OAI</button>
      </div>
  {/* Editable text area */}
  <textarea
    value={editableMessage}
    onChange={handleEditableChange} // Update state on change
    style={{
      marginTop: '20px',
      width: '50vw',  // 50% of the viewport width
      height: '20vh', // 20% of the viewport height
      resize: 'none', // Optionally prevent resizing
    }}
  />
  {/* Container for the next two text areas */}
  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
    <textarea
      value={readMessage}
      readOnly
      style={{
        width: 'calc(50vw - 10px)', // Adjusting width to account for 2 textareas side by side minus margin
        height: '20vh', // Matching height
        resize: 'none' // Prevents resizing
      }}
    />
    <textarea
      value={prepMessage}
      readOnly
      style={{
        width: 'calc(50vw - 10px)', // Adjusting width to account for 2 textareas side by side minus margin
        height: '20vh', // Matching height
        resize: 'none', // Optionally prevent resizing
      }}
    />
  </div>
</div>



  );
};

export default Tweety;
