// src/pages/Methods.tsx
import React, { useState } from 'react';
import { PROD_or_DEV } from '../config';

console.log(PROD_or_DEV); // Use it as needed
let MASTERURLPREFIX : string = "UNKNOWN/";
const prodString: string = "PROD"
const devString: string = "DEV"

if (PROD_or_DEV === prodString) {
  MASTERURLPREFIX = "https://boocrewnft.co.uk/";
  console.log("We are in the production environment.");
} else if (PROD_or_DEV === devString) {
  MASTERURLPREFIX = "http://localhost:5000/";
  console.log("We are in the development environment.");
} else {
  console.log("The environment is not specified as 'DEV' or 'PROD'.");
}

const Methods: React.FC = () => {
  const [message, setMessage] = useState(''); // State to hold the message for the text box

  const handleButtonClick = async (methodNumber: number) => {
    let url = '';
    let endpoint = "/UNKNOWN";
    endpoint = "a"
    switch (methodNumber) {
      case 1:
        endpoint = "run-method1"
        //url = 'http://localhost:5000/run-method1';
        break;
      case 2:
        endpoint = "run-method2"
        //url = 'http://localhost:5000/run-method2';
        break;
      case 3:
        endpoint = "run-method3"
        //url = 'http://localhost:5000/run-method3';
        break;
      default:
        console.error('Invalid method number');
        return;
    }

    try {
      url = `${MASTERURLPREFIX}${endpoint}`;
      const response = await fetch(url, { method: 'POST' });
      const data = await response.json();
      setMessage(data.message); // Update the text box with the response message
    } catch (error) {
      console.error('Error fetching data:', error);
      setMessage('Failed to fetch data'); // Set error message if request fails
    }
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <h1>Methods Page</h1>
      <p>This is the Methods page.</p>
      <div>
        <button onClick={() => handleButtonClick(1)}>Method 1</button>
        <button onClick={() => handleButtonClick(2)}>Method 2</button>
        <button onClick={() => handleButtonClick(3)}>Method 3</button>
      </div>
      {/* Text box to display messages */}
      <textarea value={message} readOnly style={{ marginTop: '20px', width: '300px', height: '100px' }} />
    </div>
  );
};

export default Methods;
