import React, { useState, useRef } from 'react';
import '../MapStyles.css'; // Assuming you have CSS for your map

const IslandMap: React.FC = () => {
    const [position, setPosition] = useState({ x: -100, y: -100 });
    const svgRef = useRef<SVGSVGElement>(null);

    const handleMouseMove = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      if (svgRef.current) {
        const rect = svgRef.current.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;
        setPosition({ x, y });
      }
    };

    const handleMouseLeave = () => {
      setPosition({ x: -100, y: -100 });
    };

    return (
      <svg
      width="600"
      height="400"
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      ref={svgRef}
      xmlns="http://www.w3.org/2000/svg"
    >


      <rect x="0" y="0" width="600" height="400" 
      fill="#40e0d0" stroke="black" strokeWidth="3" mask="url(#torchMask)" />



      <path id="beach" d="M554,150 L93,200 300,377 T490,375 Z" 
      fill="#fddfa2" stroke="black" strokeWidth="3" mask="url(#torchMask)" />

      <path id="forest" d="M50,380 L28,210 150,250 L300,377 Z" 
      fill="#0f9d58" stroke="black" strokeWidth="3" mask="url(#torchMask)" />

      <path id="mountain" d="M250,16 L590,25 L554,150 Z" 
      fill="#8c8c89" stroke="black" strokeWidth="3" mask="url(#torchMask)" />

      <path id="lake" d="M50,10 L10,25 L10,75 L28,210 L150,250 L93,200 L554,150 L250,16 Z" 
      fill="#5792f0" stroke="black" strokeWidth="3" mask="url(#torchMask)" />
    </svg>
    );
};

export default IslandMap;

          {/*
      
          <defs>
            <mask id="torchMask">
              <rect width="100%" height="100%" fill="black" />
              <circle cx={position.x} cy={position.y} r="80" fill="white" />
            </mask>
          </defs>

                <!-- <polygon points="50,10 590,25 490,375 50,380 10,75 10,25" 
      fill="#f4c542" stroke="black" strokeWidth="3" mask="url(#torchMask)" /> -->
            
          */}