import React, { useState } from 'react';
import axios, { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import { useAuth } from '../AuthContext'; // Adjust the import path as needed
import { PROD_or_DEV } from '../config';

console.log(PROD_or_DEV); // Use it as needed
let MASTERURLPREFIX : string = "UNKNOWN/";
const prodString: string = "PROD"
const devString: string = "DEV"

if (PROD_or_DEV === prodString) {
  MASTERURLPREFIX = "https://boocrewnft.co.uk/";
  console.log("We are in the production environment.");
} else if (PROD_or_DEV === devString) {
  MASTERURLPREFIX = "http://localhost:5000/";
  console.log("We are in the development environment.");
} else {
  console.log("The environment is not specified as 'DEV' or 'PROD'.");
}

function LoginForm() {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [loginMessage, setLoginMessage] = useState<string>('');

  const { login } = useAuth(); // Use the login function from AuthContext
  const navigate = useNavigate(); // For redirecting after login

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      //const response = await axios.post('http://localhost:5000/login', { username, password });
      const endpoint = "login";
      const fullURL = `${MASTERURLPREFIX}${endpoint}`;
      const response = await axios.post(fullURL, { username, password });
      if (response.status === 200) {
        console.log(response.data.message);
        login(username); // Update the auth context to indicate user is logged in
        navigate('/coinz'); // Redirect to home or dashboard page
      } else {
        setLoginMessage('Login failed: Invalid credentials');
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError.response && axiosError.response.status === 401) {
        setLoginMessage('Wrong username or password');
      } else {
        setLoginMessage('Login failed due to technical issues. Please try again later.');
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Username:
        <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
      </label>
      <label>
        Password:
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
      </label>
      <button type="submit">Login</button>
      {/* Display login feedback message */}
      {loginMessage && <p>{loginMessage}</p>}
    </form>
  );
}

export default LoginForm;
