import React, { useState } from 'react';
import DBEditor from '../components/DBEditor';
import ApiDataLoader from '../components/ApiDataLoader';
import DBRecordAdder from '../components/DBRecordAdder';

const Coinz = () => {
  const [activeComponent, setActiveComponent] = useState<'editor' | 'loader' | 'adder'>('editor');

  // Explicitly typed as React.CSSProperties
  const pageStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Keeps items centered horizontally.
    justifyContent: 'flex-start', // Aligns items to the start vertically.
    gap: '20px',
    paddingTop: '20px',
    height: '100vh', // Makes the container take up the full viewport height.
    width: '100%',
  };

  const buttonContainerStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
    width: '100%',
  };

  return (
    <div style={pageStyle}>
      <div style={buttonContainerStyle}>
        <button onClick={() => setActiveComponent('editor')}>DB Editor</button>
        <button onClick={() => setActiveComponent('loader')}>API Data Loader</button>
        <button onClick={() => setActiveComponent('adder')}>Add Record</button>
      </div>

      {activeComponent === 'editor' && <DBEditor />}
      {activeComponent === 'loader' && <ApiDataLoader />}
      {activeComponent === 'adder' && <DBRecordAdder />}
    </div>
  );
};

export default Coinz;
