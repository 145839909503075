// App.tsx
import React from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import './App.css'; // Make sure this is imported
import GuessTheNumber from './pages/GuessTheNumber';
import Methods from './pages/methods'; // Adjust the import path according to your file structure
import Coinz from './pages/coinz'; // Adjust the import path according to your file structure
import LoginForm from './pages/loginform';
import SignupForm from './pages/signupform';
import Tweety from './pages/tweety';
import HomePage from './pages/homepage';
import { useAuth } from './AuthContext';
import { AuthProvider } from './AuthContext';
import Header from './components/Header'; // Adjust the import path as needed
import ProtectedRoute from './ProtectedRoute';
import UpdateRow from './components/UpdateRow'; // Make sure this import is correct
import { MainGameApp } from './pages/maingameapp';

// In any React component
import { PROD_or_DEV } from './config';

console.log(PROD_or_DEV); // Use it as needed

//<Route path="/coinz" element={<ProtectedRoute><Coinz /></ProtectedRoute>} />

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
      <Header /> {/* This will appear on every page */}
        <div className="centered-content">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/methods" element={<ProtectedRoute><Methods /></ProtectedRoute>} />
              <Route path="/coinz" element={<ProtectedRoute><Coinz /></ProtectedRoute>} />
              <Route path="/tweety" element={<ProtectedRoute><Tweety /></ProtectedRoute>} />
              <Route path="/loginform" element={<LoginForm />} />
              <Route path="/signupform" element={<SignupForm />} />
              <Route path="/maingameapp" element={<MainGameApp />} />
              <Route path="/guessthenumber" element={<GuessTheNumber />} />
              <Route path="/update-row" element={<ProtectedRoute><UpdateRow /></ProtectedRoute>} />
              {/* Define other routes */}
            </Routes>
        </div>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
