// src/components/Header.tsx
import React from 'react';
import { useAuth } from '../AuthContext'; // Adjust the import path as needed
import { Link } from 'react-router-dom';
import './Header.css'; // Assuming you will create a Header.css file for styles

const Header = () => {
  const { isAuthenticated, user, logout } = useAuth();

  return (
    <header className="header">
      <nav className="nav">
        <div className="nav-links">
          <Link to="/">Home</Link> | 
          <Link to="/methods">Methods</Link> | 
          <Link to="/loginform">Login</Link> | 
          <Link to="/signupform">Signup</Link> |
          <Link to="/guessthenumber">Guess</Link> |
          <Link to="/coinz">Coinz</Link> |
          <Link to="/maingameapp">Game</Link> |
          <Link to="/tweety">Tweety</Link>

        </div>
        <div className="login-info">
          {isAuthenticated ? (
            <>
              <span>Welcome, {user?.username}!</span>
              <button onClick={logout}>Logout</button>
            </>
          ) : (
            <span>Please log in.</span>
          )}
        </div>
      </nav>
    </header>
  );
};

export default Header;
