// src/pages/homePage.tsx
import React, { useState } from 'react';
import adaLogo from '../assets/adagif.gif'

const HomePage: React.FC = () => {
  const [message, setMessage] = useState(''); // State to hold the message for the text box

  const handleLogoClick = async () => {
    let url = 'http://localhost:5000/getNMKR';
    

    try {
      const response = await fetch(url, { method: 'POST' });
      const data = await response.json();
      setMessage(data.message); // Update the text box with the response message
    } catch (error) {
      console.error('Error fetching data:', error);
      setMessage('Failed to fetch data'); // Set error message if request fails
    }
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <h1>HOME Page</h1>
      <p>This is the HOME page.</p>
      <div>

      <img src={adaLogo} className="logo" alt="Ada logo" onClick={handleLogoClick} />
      
      </div>
      {/* Text box to display messages */}
      <textarea 
  value={message} 
  readOnly 
  style={{ 
    marginTop: '20px', 
    width: '50vw', // 50% of the viewport width
    height: '20vh', // 20% of the viewport height
    resize: 'none' // Optional: Prevents resizing
  }} 
/>
    </div>
  );
};

export default HomePage;
